$('#toggle').on('click', function () {
  $(this).toggleClass('is-toggleActive');
  $('html').toggleClass('open');

  if ( $(this).hasClass('is-toggleActive') ) {
    $('#gnavi').addClass('is-navOpen');
  } else {
    $('#gnavi').removeClass('is-navOpen');
  }
});

$('.l-navigation__item a[href^="#"]').on('click', function() {
  $('#toggle').removeClass('is-toggleActive');
  // $('#gnavi').css('display','none');
  $('#gnavi').removeClass('is-navOpen');
  $('html').removeClass('open');
});



// メインビジュアルの要素を取得
const pSliderContainer = document.querySelector('.p-slider__container');
// ヘッダーメニューのリンク要素を取得
const navigationLinks = document.querySelectorAll('.l-navigation');
// ハンバーガーの要素を取得
const toggleBtn = document.querySelectorAll('.c-toggleBtn');

// スクロールイベントを監視して処理を実行
window.addEventListener('scroll', () => {
  // スクロール量を取得
  const scrollY = window.scrollY || window.pageYOffset;

  // メインビジュアルの高さを取得
  const pSliderContainerHeight = pSliderContainer.offsetHeight;
  // 三角分をマイナスする
  const kvHeight = pSliderContainerHeight - 81;

  // p-slider__containerの高さを越えた場合
  if (scrollY > kvHeight) {
    // l-navigationにchange-colorクラスを付与
    navigationLinks.forEach(link => {
      link.classList.add('u-reverse-color');
    });
  } else {
    // p-slider__containerの高さ未満に戻るとchange-colorクラスを削除
    navigationLinks.forEach(link => {
      link.classList.remove('u-reverse-color');
    });
  }

  // p-slider__containerの高さを越えた場合
  if (scrollY > kvHeight) {
    // c-toggleBtnにchange-colorクラスを付与
    toggleBtn.forEach(link => {
      link.classList.add('u-reverse-color');
    });
  } else {
    // p-slider__containerの高さ未満に戻るとchange-colorクラスを削除
    toggleBtn.forEach(link => {
      link.classList.remove('u-reverse-color');
    });
  }
});

